$(document).ready(function(){

  //Admin Sidebar -----
  // $(".admin-container .sidenav-panel > .cardcircle.has-subnav").click(function(){
  //   $(".cardcircle.show-sidebar").removeClass("show-sidebar");
  //   $(this).addClass("show-sidebar ");
  //   $(".admin-content .a-overlaver").css("display", "block");
  //   $(".admin-container").addClass("ds-active");
  // });

  // $(".admin-container .sidebar-sub-nav > #subNavLink").click(function(){
  //   $(".cardcircle.show-sidebar").removeClass("show-sidebar");
  //   $(".admin-container").removeClass("ds-active");
  // });

  // $(".admin-container .admin-content .a-overlaver").click(function(){
  //   $(".cardcircle.show-sidebar").removeClass("show-sidebar");
  //   $(".admin-content .a-overlaver").css("display", "none");
  //   $(".admin-container").removeClass("ds-active");
  // });

  // $(".admin-container .sidebar-sub-nav > #subNavLink").click(function(){
  //   $(".sidebar-sub-nav > #subNavLink.active").removeClass("active");
  //   $(this).addClass("active");
  // });

});

